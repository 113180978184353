<template>
  <div class="flex flex-col gap-4">
    <div class="flex gap-4">
      <div class="flex basis-1/2 flex-col gap-4">
        <div class="flex justify-center">
          <YmiField
            label="Дата"
            semibold
          >
            <Datepicker
              v-model="modalStore.postponement.data.day"
              menu-class-name="!rounded-lg !border-base-400"
              calendar-cell-class-name="!rounded-lg"
              locale="ru"
              inline
              auto-apply
              :enable-time-picker="false"
              :year-range="[2023]"
              hide-offset-dates
              :min-date="minDate"
              prevent-min-max-navigation
            />
          </YmiField>
        </div>
      </div>
      <div class="flex flex-col gap-4">
        <div>
          <YmiField
            label="Время клиента"
            semibold
          >
            <div class="flex gap-2">
              <div class="flex grow items-center rounded-md border border-zinc-300 px-3 py-2">
                {{ clientTimeFormatted }}
              </div>
              <YmiButton
                class="btn-outline btn-ghost btn-square"
                :loading="isLoadingClientTime"
                @click="handleForceTimeUpdate"
              >
                <Icon
                  v-if="!isLoadingClientTime"
                  name="refresh"
                />
              </YmiButton>
            </div>
          </YmiField>
        </div>
        <div class="flex justify-center">
          <YmiField
            label="Время действия"
            semibold
          >
            <Datepicker
              v-model="modalStore.postponement.data.time"
              menu-class-name="!rounded-lg !border-base-400"
              input-class-name="!rounded-lg !border-base-400"
              locale="ru"
              auto-apply
              time-picker
              :min-time="minTimeByDate"
              @update:model-value="handleTimeUpdate"
            />
          </YmiField>
        </div>
        <div class="flex grow">
          <YmiField
            class="flex grow"
            label="Комментарий"
            semibold
          >
            <YmiTextArea v-model="modalStore.postponement.comment" />
          </YmiField>
        </div>
      </div>
    </div>
    <div>
      <ResidenceCity
        class="flex grow"
        position="top"
        label="Город проживания клиента"
        @chosen="updateClientTime"
      />
    </div>
  </div>
</template>

<script setup>
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import dayjs from '~/helpers/dayjs';
import { useDocumentStore } from '~/stores/document';
import { useModalStore } from '~/stores/modal';

const documentStore = useDocumentStore();
const modalStore = useModalStore();

const isLoadingClientTime = ref(false);

const clientTimeFormatted = computed(() => {
  if (!modalStore.postponement.data.clientTime) {
    return 'Не определено';
  }

  return modalStore.postponement.data.clientTime;
});

const minTimeByDate = computed(() => {
  if (!modalStore.postponement.data.clientTime) {
    return {
      hours: modalStore.postponement.data.minHour,
      minutes: modalStore.postponement.data.minMinute,
    };
  }

  const clientDate = dayjs(modalStore.postponement.data.clientTime, 'DD.MM.YYYY HH:mm:ss');
  const dateDiff = dayjs(modalStore.postponement.data.day).date() !== clientDate.date();

  if (dateDiff) {
    return { hours: 0, minutes: 0 };
  }

  return {
    hours: clientDate.hour(),
    minutes:
      clientDate.hour() === modalStore.postponement?.data?.time?.hours ? clientDate.minute() : 0,
  };
});

const minDate = computed(() => {
  if (!modalStore.postponement.data.clientTime) {
    return new Date();
  }

  return dayjs(modalStore.postponement.data.clientTime, 'DD.MM.YYYY HH:mm:ss').toDate();
});

const { $api } = useNuxtApp();

const updateClientTime = async () => {
  if (!documentStore.cityKLADR_residence_dadata?.data?.timezone) {
    return;
  }

  const { data } = await $api.web.GetClientTime(
    documentStore.cityKLADR_residence_dadata.data.timezone,
  );

  modalStore.postponement.data.clientTime = data.data.formatted;

  if (!modalStore.postponement.data.clientTime) {
    modalStore.postponement.data.minHour = data.data.minHour;
  }
  if (!modalStore.postponement.data.day) {
    modalStore.postponement.data.day = new Date();
  }
};

const handleTimeUpdate = (val) => {
  if (!val) return;

  const utc = Number(documentStore.cityKLADR_residence_dadata?.data?.timezone.slice(-2));

  const date_of_chime = dayjs(modalStore.postponement.data.day)
    .hour(val.hours)
    .minute(val.minutes)
    .format('DD.MM.YYYY HH:mm:ss');

  modalStore.$patch({
    postponement: {
      utc,
      date_of_chime,
    },
  });
};

const handleForceTimeUpdate = async () => {
  if (!documentStore.cityKLADR_residence_dadata?.data?.timezone) {
    return;
  }

  isLoadingClientTime.value = true;
  await updateClientTime();

  setTimeout(() => (isLoadingClientTime.value = false), 1500);
};

onMounted(() => nextTick(async () => await updateClientTime()));
</script>

<style lang="postcss">
/* .dp__button {
  background: transparent !important;
} */
.dp__overlay_cell_disabled {
  @apply hidden;
}

[aria-label='Open years overlay'] {
  @apply pointer-events-none;
}

.dp__theme_light {
  --dp-primary-color: hsl(var(--in) / var(--tw-bg-opacity));
  --dp-primary-text-color: #000;
}
</style>
