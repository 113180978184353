<template>
  <YmiModal
    :visible="modalStore.login.visible"
    custom-class="md:w-1/2 overflow-hidden"
    :close-options="[]"
    @close="modalStore.login.reset"
  >
    <div class="flex flex-col gap-4">
      <LoginForm embeded />
    </div>
  </YmiModal>
</template>

<script setup>
import { useModalStore } from '~/stores/modal';

const modalStore = useModalStore();
</script>
