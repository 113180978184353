<template>
  <Dropdown
    place="top-left"
    :visible="isDropdownVisible"
    @close="hideDropdown"
  >
    <template #label>
      <YmiButton
        class="btn-info"
        size="sm"
        tabindex="0"
        expanded
        :disabled="!isValidPhone || documentIsReadOnly"
        @click="toggleDropdownVisibility"
      >
        <Icon name="alarm" />
        Отложить
        <Icon
          size="xs"
          :class="{
            'fill-black': isValidPhone,
            'fill-zinc-400': !isValidPhone,
          }"
          :name="isDropdownVisible ? 'caret-up' : 'caret-down'"
        />
      </YmiButton>
    </template>
    <div
      v-if="isDropdownVisible"
      class="flex w-[320px] flex-col gap-2 overflow-auto p-2 text-left text-xs"
    >
      <div
        v-for="item in options"
        :key="item.group"
        class="flex flex-col gap-1"
      >
        <div>
          <span class="font-semibold">{{ item.group }}</span>
        </div>
        <div @mousedown="chooseHandler">
          <div
            v-for="row in item.items"
            :key="row.guid"
            class="cursor-pointer p-2 hover:bg-slate-100"
            :data-item="JSON.stringify(row)"
          >
            {{ row.name }}
          </div>
        </div>
      </div>
    </div>
  </Dropdown>
</template>

<script setup>
import { storeToRefs } from 'pinia';
import { useAppStore } from '~/stores/app';
import { useDocumentStore } from '~/stores/document';
import { useModalStore } from '~/stores/modal';
import { useReferencesStore } from '~/stores/references';

const appStore = useAppStore();
const documentStore = useDocumentStore();
const modalStore = useModalStore();
const referencesStore = useReferencesStore();

const router = useRouter();

const { isEmptyResidence, isValidPhone, documentIsReadOnly } = storeToRefs(documentStore);

const isDropdownVisible = ref(false);

const comp = markRaw(resolveComponent('SelectPostponeReason'));
const chooseHandler = (event) => {
  try {
    const item = JSON.parse(event.target.dataset.item);

    if (event.which !== 1) return;

    modalStore.confirm.create({
      dynamicComponent: referencesStore.selectablePostponeReasonsByTimer.find(
        (i) => i.guid === item.guid,
      )
        ? comp
        : '',
      title: `Отложить по причине «${item.name}»?`,
      trueCallback: async () => {
        Object.assign(modalStore.postponement, { name: item.name, guid: item.guid });
        await documentStore.postpone();

        appStore.$patch({ appealStatus: 'postpone' });
        router.push('/finish');
      },
      trueDisabled: item.name !== 'Сброс вызова (сброс после гудков)' && isEmptyResidence,
      closeCallback: () => {
        // modalStore.postponement.reset();
      },
    });
  } catch (err) {
    // eslint-disable-next-line
    console.log(err);
  }
};

const toggleDropdownVisibility = () => {
  isDropdownVisible.value = !isDropdownVisible.value;
};

const hideDropdown = () => {
  isDropdownVisible.value = false;
};

const options = [
  {
    group: 'Таймер',
    items: referencesStore.selectablePostponeReasonsByTimer,
  },
  {
    group: 'По причине',
    items: referencesStore.selectablePostponeReasonsByValue,
  },
];
</script>
