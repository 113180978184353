<template>
  <div class="flex flex-row items-center justify-center gap-2">
    <div>
      <YmiButton
        class="btn-ghost"
        size="sm"
        :disabled="appStore.step === 1"
        @click="appStore.toStep(1)"
      >
        <Icon name="chevron-back" />
      </YmiButton>
    </div>
    <div class="w-[140px] select-none text-center">
      {{ stepName }}
    </div>
    <div>
      <YmiButton
        class="btn-ghost"
        size="sm"
        :disabled="appStore.toStep2IsBanned"
        @click="appStore.toStep(2)"
      >
        <Icon name="chevron-forward" />
      </YmiButton>
    </div>
  </div>
</template>

<script setup>
import { useAppStore } from '~/stores/app';

const appStore = useAppStore();

const stepName = computed(() => {
  try {
    const source = {
      1: 'Консультация',
      2: 'Анкета',
    };
    return source[appStore.step] || '';
  } catch (err) {
    // eslint-disable-next-line
    console.log(err);
    return '';
  }
});
</script>
