<template>
  <div class="relative">
    <div
      class="absolute z-[5]"
      :class="[menuPlace]"
    >
      <Transition name="scale-in">
        <div
          v-if="props.visible"
          ref="target"
          class="rounded-lg p-4 shadow-lg"
          :class="props.menuClass ? props.menuClass : 'bg-slate-50'"
        >
          <slot></slot>
        </div>
      </Transition>
    </div>
    <slot name="label"></slot>
  </div>
</template>

<script setup>
import { onClickOutside } from '@vueuse/core';

const emit = defineEmits(['close']);

const props = defineProps({
  visible: {
    type: Boolean,
    default: false,
  },
  place: {
    type: String,
    default: 'bottom-right',
  },
});

const target = ref(null);

onClickOutside(target, () => {
  emit('close');
});

const places = {
  'bottom-right': 'top-full bottom-auto',
  'bottom-left': 'top-full bottom-auto right-0 ',
  'top-right': 'bottom-full top-auto',
  'top-left': 'bottom-full top-auto right-0',
  left: 'right-full',
  right: 'left-full',
};

const menuPlace = computed(() => places[props.place]);
</script>
