<template>
  <YmiModal
    title="Уточнить данные"
    :visible="modalStore.specifyAppeal.visible"
    @close="modalStore.specifyAppeal.visible = false"
  >
    <SpecifyAppeal />
  </YmiModal>
</template>

<script setup>
import { useModalStore } from '~/stores/modal';

const modalStore = useModalStore();
</script>
