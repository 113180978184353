<template>
  <YmiTooltip label="Добавить комментарий">
    <YmiButton
      class="btn-outline btn-ghost btn-square"
      size="sm"
      @click="addComment"
    >
      <Icon
        size="sm"
        name="add-comment"
      />
    </YmiButton>
  </YmiTooltip>
</template>

<script setup>
import { useModalStore } from '~/stores/modal';

const modalStore = useModalStore();

const AddComment = markRaw(resolveComponent('AddComment'));

const addComment = () => {
  modalStore.confirm.create({
    title: 'Комментарий',
    trueText: 'Добавить',
    falseText: 'Отмена',
    customClass: 'w-[600px]',
    dynamicComponent: AddComment,
    trueCallback: async () => {},
  });
};
</script>
