<template>
  <YmiButton
    class="btn-outline btn-ghost btn-square"
    size="sm"
    @click="logout"
  >
    <Icon
      size="sm"
      name="logout"
    />
  </YmiButton>
</template>

<script setup>
import { useAuthStore } from '~/stores/auth';
import { useModalStore } from '~/stores/modal';

const authStore = useAuthStore();
const modalStore = useModalStore();

const logout = () => {
  modalStore.confirm.create({
    trueCallback: async () => {
      await authStore.logout();
    },
  });
};
</script>
