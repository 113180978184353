<template>
  <YmiModal
    ref="modal"
    :close-options="modalStore.confirm.closeOptions"
    :visible="modalStore.confirm.visible"
    :title="modalStore.confirm.title"
    :size="modalStore.confirm.size"
    :custom-class="modalStore.confirm.customClass"
    z-index="901"
    @close="modalStore.confirm.finishCallback"
  >
    <div class="flex flex-col gap-4">
      <div v-if="modalStore.confirm.dynamicComponent">
        <component :is="modalStore.confirm.dynamicComponent" />
      </div>
      <div v-if="!modalStore.confirm.dynamicComponent && modalStore.confirm.message">
        {{ modalStore.confirm.message }}
      </div>
      <div
        class="flex justify-end gap-4"
        :class="{ 'border-t pt-4': modalStore.confirm.dynamicComponent }"
      >
        <YmiButton
          :loading="modalStore.confirm.trueLoading"
          :disabled="modalStore.confirm.trueDisabled"
          data-test="btn_confirmModal_confirm"
          @click="modalStore.confirm.trueCallback"
        >
          {{ modalStore.confirm.trueText }}
        </YmiButton>
        <YmiButton
          class="btn-outline"
          data-test="btn_confirmModal_reject"
          @click="modalStore.confirm.falseCallback"
        >
          {{ modalStore.confirm.falseText }}
        </YmiButton>
      </div>
    </div>
  </YmiModal>
</template>

<script setup>
import { useModalStore } from '~/stores/modal';

const modalStore = useModalStore();
const modal = ref(null);
</script>
