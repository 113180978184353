<template>
  <div class="flex flex-col gap-4">
    <YmiField
      v-if="modalStore.complaint.guid === 'b0488e87-4cfd-11e3-9422-3440b59fff32'"
      label="Категория жалобы"
      semibold
    >
      <select
        v-model="documentStore.category_of_complaint_guid"
        class="select-bordered select w-full max-w-fit outline-none"
      >
        <option value="">-- выбрать --</option>
        <option
          v-for="option in referencesStore.categoriesOfComplaints"
          :key="option.guid"
          :value="option.guid"
          :selected="option.guid === modalStore.complaint.guid"
        >
          {{ option.name }}
        </option>
      </select>
    </YmiField>
    <YmiField
      label="Комментарий"
      semibold
    >
      <YmiTextArea v-model="documentStore.document_comment" />
    </YmiField>
  </div>
</template>

<script setup>
import { useDocumentStore } from '~/stores/document';
import { useModalStore } from '~/stores/modal';
import { useReferencesStore } from '~/stores/references';

const documentStore = useDocumentStore();
const modalStore = useModalStore();
const referencesStore = useReferencesStore();
</script>
