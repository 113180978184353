<template>
  <YmiTooltip label="Посмотреть «Отложенные»">
    <YmiButton
      class="btn-outline btn-ghost btn-square"
      size="sm"
      @click="modalStore.deferredAppealComments.open(route.query.document_guid)"
    >
      <Icon
        size="sm"
        name="chatbubbles"
      />
    </YmiButton>
  </YmiTooltip>
</template>

<script setup>
import { useModalStore } from '~/stores/modal';

const modalStore = useModalStore();
const route = useRoute();
</script>
