<template>
  <div
    class="z-[800] flex h-14 w-full items-center justify-between gap-4 rounded-none bg-slate-100 px-4 shadow-[rgba(0,0,0,0.05)_0px_6px_24px_0px,rgba(0,0,0,0.08)_0px_0px_0px_1px]"
  >
    <div class="flex basis-1/3 items-center gap-2">
      <div>
        <ButtonGoToDashboard />
      </div>
      <div
        v-if="appStore.isPageCreate"
        class="grow"
      >
        <ManageStep />
      </div>
    </div>
    <div
      v-if="appStore.isPageCreate"
      class="flex basis-1/3 justify-center gap-2 text-center"
    >
      <ButtonReject class="grow" />
      <ButtonPostpone class="grow" />
    </div>
    <div class="flex basis-1/3 justify-end gap-2 text-center">
      <div v-if="appStore.isPageCreate">
        <ButtonAppealComments />
      </div>
      <div v-if="appStore.isPageCreate">
        <ButtonAddComment />
      </div>
      <div v-if="appStore.isPageCreate">
        <ButtonPhoneManagement />
      </div>
      <div>
        <ButtonLogout />
      </div>
    </div>
  </div>
</template>

<script setup>
import { useAppStore } from '~/stores/app';

const appStore = useAppStore();
</script>
