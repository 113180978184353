<template>
  <div class="h-screen">
    <div class="h-[calc(100vh-56px)] overflow-y-auto text-accent-content">
      <main class="p-4">
        <slot />
      </main>
      <ModalConfirm />
      <ModalReportAppeals />
      <ModalRequestInfo />
      <ModalPhoneManagementActions />
      <ModalSpecifyAppeal />
      <ModalDeferredAppealComments />
      <ModalLogin />
    </div>
    <UserActions v-if="!appStore.isPageTypeTask" />
    <YmiNotifications
      :mapped-store="notieStore"
      transition-name="scale-in"
      default-position="top-right"
    />
  </div>
</template>

<script setup>
import { useAppStore } from '~/stores/app';
import { useNotieStore } from '~/stores/notifications';

const appStore = useAppStore();
const notieStore = useNotieStore();

const { $ws } = useNuxtApp();
const config = useRuntimeConfig();

await $ws.useConnection({
  url: config.public.wssUrl,
  label: 'main',
  settings: { secure: true },
});
</script>
