<template>
  <div class="flex flex-col gap-4">
    <div>
      <textarea
        v-model="documentStore.request_comment"
        class="textarea-bordered textarea h-[140px] w-full"
      />
    </div>
  </div>
</template>

<script setup>
import { useDocumentStore } from '~/stores/document';

const documentStore = useDocumentStore();
</script>
