<template>
  <div
    v-if="!modalStore.requestInfo.remotePdfDocument"
    class="flex h-[400px] items-center justify-center"
  >
    <YmiIsLoading size="lg" />
  </div>
  <div
    v-else
    class="flex w-full justify-center"
  >
    <pdf
      class="h-[800px] w-[600px]"
      :src="pdfDocument"
    />
  </div>
</template>

<script setup>
import { useModalStore } from '~/stores/modal';

const modalStore = useModalStore();

const isLoading = ref(false);

const pdfDocument = computed(() => {
  return modalStore.requestInfo.remotePdfDocument;
});

const { $api } = useNuxtApp();

onMounted(async () => {
  try {
    if (!modalStore.requestInfo.request_guid) return;
    isLoading.value = true;
    const { data } = await $api.web.GetRequestInfo(modalStore.requestInfo.request_guid);
    modalStore.requestInfo.remotePdfDocument = data.data;
  } catch (err) {
    // eslint-disable-next-line
    console.log(err);
  } finally {
    isLoading.value = false;
  }
});
</script>
