<template>
  <YmiModal
    title="Комментарии сотрудников"
    :visible="modalStore.deferredAppealComments.visible"
    @close="modalStore.deferredAppealComments.reset"
  >
    <DeferredAppealComments
      v-if="modalStore.deferredAppealComments.visible"
      :guid="modalStore.deferredAppealComments.documentGuid"
    />
  </YmiModal>
</template>

<script setup>
import { useModalStore } from '~/stores/modal';

const modalStore = useModalStore();
</script>
