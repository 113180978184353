<template>
  <div
    class="w-full rounded-lg p-4 shadow-tile even:bg-zinc-50"
    :class="{ 'first:border-2 first:border-accent': totalItems > 1 }"
  >
    <div class="flex items-center justify-between gap-2">
      <div class="font-semibold text-sky-500">
        {{ data.operator_text }}
      </div>
      <div class="text-sm text-slate-400">
        {{ dateOfCall }}
      </div>
    </div>
    <div
      v-if="['Перезвонить', 'Дозаполнить'].includes(data.pending_state_guid)"
      class="flex items-center gap-2"
    >
      <div>{{ data.pending_state_text.replace('...', '') }}</div>
      <div class="underline decoration-pink-400 underline-offset-4">{{ timeOfChime }}</div>
      <div>{{ dateOfChime }}</div>
      <div v-if="data.comment">, {{ data.comment }}</div>
    </div>
    <div
      v-else
      class="flex items-center gap-2"
    >
      <div>{{ data.pending_state_text }}</div>
    </div>
  </div>
</template>

<script setup>
import dayjs from 'dayjs';

const props = defineProps({
  data: {
    type: Object,
    default: () => {},
  },
  totalItems: {
    type: Number,
    default: 0,
  },
});

const dateOfCall = computed(() => {
  if (!props.data.date_of_call || props.data.date_of_call === '0001-01-01T00:00:00') return '';

  return dayjs(props.data.date_of_call).format('DD.MM.YYYY в HH:mm:ss');
});

const timeOfChime = computed(() => {
  if (!props.data.date_of_chime || props.data.date_of_chime === '0001-01-01T00:00:00') return '';

  return dayjs(props.data.date_of_chime).format('HH:mm');
});

const dateOfChime = computed(() => {
  if (!props.data.date_of_chime || props.data.date_of_chime === '0001-01-01T00:00:00') return '';

  return dayjs(props.data.date_of_chime).format('DD.MM.YYYY');
});
</script>

<style scoped lang="postcss">
.firstComment {
  @apply border border-red-400;
}
</style>
