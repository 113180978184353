<template>
  <YmiButton
    class="btn-outline btn-ghost btn-square"
    size="sm"
    @click="goToDashboard"
  >
    <Icon
      size="sm"
      name="grid"
    />
  </YmiButton>
</template>

<script setup>
import { pages } from '~/stores/auth';
import { useModalStore } from '~/stores/modal';

const modalStore = useModalStore();
const router = useRouter();
const route = useRoute();

const goToDashboard = () => {
  if (route.path === pages.index) return;
  modalStore.confirm.create({
    title: 'Перейти в главное меню?',
    trueCallback: () => {
      router.push(pages.index);
    },
  });
};
</script>
