<template>
  <div class="flex flex-col gap-4">
    <YmiField
      label="Номер телефона"
      semibold
    >
      <div
        ref="wrapper"
        class="flex gap-2"
      >
        <YmiInput
          v-model="phone"
          v-maska
          data-maska="8 (###) ###-##-##"
          :disabled="isLoading"
          class="grow"
        />
        <YmiButton
          class="btn-outline btn-ghost btn-square"
          size="sm"
          :disabled="!isValid || isLoading || ['isInList', 'added'].includes(result)"
          @click="add"
        >
          <Icon name="add"></Icon>
        </YmiButton>
        <YmiButton
          class="btn-outline btn-ghost btn-square"
          size="sm"
          :disabled="!isValid || isLoading || ['isNotInList', 'removed'].includes(result)"
          @click="remove"
        >
          <Icon name="trash"></Icon>
        </YmiButton>
      </div>
    </YmiField>
    <div
      v-if="result || isLoading"
      class="flex items-center justify-center rounded-lg p-4"
      :class="{
        'bg-slate-100': ['isInList', 'isNotInList'].includes(result) || isLoading,
        'bg-emerald-100': result === 'added',
        'bg-red-100': ['removed', 'notAdded', 'notRemoved'].includes(result),
      }"
    >
      <YmiIsLoading v-if="isLoading" />
      <span v-else>{{ results[result] }}</span>
    </div>
  </div>
</template>

<script setup>
import { useDocumentStore } from '~/stores/document';
import { useModalStore } from '~/stores/modal';

const { $api } = useNuxtApp();
const documentStore = useDocumentStore();
const modalStore = useModalStore();

const result = ref('');
const wrapper = ref(null);
const phone = ref('');
const isValid = computed(() => phone.value.replace(/[^\d]+/g, '').length === 11);
const isLoading = ref(false);

const results = {
  isInList: 'Номер телефона найден в списке',
  isNotInList: 'Номер телефона не найден в списке',
  added: 'Номер телефона добавлен в список',
  removed: 'Номер телефона удален из списка',
  notAdded: 'Невозможно добавить номер телефона в список',
  notRemoved: 'Невозможно удалить номер телефона из списка',
};

const check = async () => {
  try {
    isLoading.value = true;
    const { data } = await $api.web[`${modalStore.phoneManagement.selected}Check`]({
      phone: phone.value.replace(/[^\d]+/g, '').slice(0, 11),
    });

    const field = modalStore.phoneManagement.selected === 'StopListSMS' ? 'date' : 'locked';
    result.value = data?.data?.[field] ? 'isInList' : 'isNotInList';
  } catch (err) {
    result.value = '';
    throw err;
  } finally {
    isLoading.value = false;
  }
};

const add = async () => {
  try {
    isLoading.value = true;
    const { data } = await $api.web[`${modalStore.phoneManagement.selected}Add`]({
      phone: phone.value.replace(/[^\d]+/g, '').slice(0, 11),
    });

    if (modalStore.phoneManagement.selected === 'StopListSMS') {
      result.value = 'added';
    }
    if (modalStore.phoneManagement.selected === 'StopListIncomingCalls') {
      result.value = data?.data?.result ? 'added' : 'notAdded';
    }
  } catch (err) {
    result.value = '';
    throw err;
  } finally {
    isLoading.value = false;
  }
};

const remove = async () => {
  try {
    isLoading.value = true;
    const { data } = await $api.web[`${modalStore.phoneManagement.selected}Delete`]({
      phone: phone.value.replace(/[^\d]+/g, '').slice(0, 11),
    });

    if (modalStore.phoneManagement.selected === 'StopListSMS') {
      result.value = 'removed';
    }
    if (modalStore.phoneManagement.selected === 'StopListIncomingCalls') {
      result.value = data?.data?.result ? 'removed' : 'notRemoved';
    }
  } catch (err) {
    result.value = '';
    throw err;
  } finally {
    isLoading.value = false;
  }
};

watch(
  () => phone.value,
  async (nv) => {
    if (nv && isValid.value) {
      await check();
    }
  },
);

onMounted(() => {
  phone.value = documentStore.phone || documentStore.mobile_phone;
  setTimeout(() => {
    if (!wrapper.value) return;
    wrapper.value.getElementsByTagName('input')[0].focus();
  }, 500);
});
</script>
