<template>
  <div>
    <div
      v-if="isLoading"
      class="flex h-[400px] items-center justify-center"
    >
      <YmiIsLoading size="lg" />
    </div>
    <div v-else>
      <div
        v-if="!comments.length"
        class="flex min-h-[100px] items-center justify-center"
      >
        Нет комментариев
      </div>
      <div
        v-else
        class="h-[400px] overflow-auto"
      >
        <div class="flex flex-col items-center gap-4 overflow-hidden p-2">
          <CommentItem
            v-for="comment in comments"
            :key="comment.date_of_call"
            :data="comment"
            :total-items="comments.length"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
  guid: {
    type: String,
    default: '',
  },
});

const { $api } = useNuxtApp();
const isLoading = ref(false);
const comments = ref([]);

const getInfo = async () => {
  try {
    if (!props.guid) return;

    isLoading.value = true;
    const { data } = await $api.web.GetDeferredComments({
      document_guid: props.guid,
    });

    if (data?.data?.length) {
      comments.value = data.data.sort((a, b) => new Date(b.date_of_call) - new Date(a.date_of_call));
    }
  } catch (err) {
    // eslint-disable-next-line
    console.log(err);
  } finally {
    isLoading.value = false;
  }
};

onMounted(async () => await getInfo());
</script>
