<template>
  <div class="flex flex-col gap-4">
    <div class="flex gap-2">
      <div
        ref="wrapper"
        class="grow"
      >
        <YmiField
          label="Начните вводить ФИО"
          semibold
        >
          <YmiInput
            v-model="names"
            @input="handleInput"
          />
        </YmiField>
      </div>
      <div class="flex items-end">
        <YmiButtonToggle
          v-model="showAllRequests"
          true-icon="checkmark-outline"
          false-icon="close"
          label="все заявки"
          @click="selectAppeal(lastSelectedAppealGuid, lastSelectedAppealName)"
        />
      </div>
    </div>
    <div>
      <YmiField
        label="Результаты поиска"
        semibold
      >
        <div v-if="!isLoading">
          <div v-if="operationStep === 1">
            <div
              v-if="searchResults.length"
              class="h-[300px] cursor-pointer overflow-auto rounded-lg border border-zinc-200 text-sm"
            >
              <div
                v-for="item in searchResults"
                :key="item.guid"
                class="select-none p-2 odd:bg-slate-50 hover:bg-slate-100"
                @click="selectAppeal(item.guid, item.name)"
              >
                {{ item.name }}
              </div>
            </div>
            <div
              v-else
              class="h-[300px] rounded-lg border border-zinc-200 p-4"
            >
              Ничего не найдено
            </div>
          </div>
          <div v-if="operationStep === 2">
            <div
              v-if="foundRequests.length"
              class="h-[300px] cursor-pointer overflow-auto rounded-lg border border-zinc-200 text-xs"
            >
              <div
                v-for="item in foundRequests"
                :key="item.request_guid"
                class="flex select-none items-center justify-between gap-4 p-2 text-center even:bg-slate-50 hover:bg-slate-100"
                @click="selectRequest(item.request_guid)"
              >
                <div>{{ item.request_number }}</div>
                <div class="flex flex-col gap-1">
                  <div>{{ dayjs(item.request_date).format('DD.MM.YYYY') }}</div>
                  <div>в {{ dayjs(item.request_date).format('HH:mm:ss') }}</div>
                </div>
                <div>{{ item.fio }}</div>
                <div>{{ dayjs(item.birthday).format('DD.MM.YYYY') }}</div>
                <div>{{ item.request_status }}</div>
              </div>
            </div>
            <div
              v-else
              class="h-[300px] rounded-lg border border-zinc-200 p-4"
            >
              Ничего не найдено
            </div>
          </div>
        </div>
        <div
          v-else
          class="flex h-[300px] items-center justify-center rounded-lg border border-zinc-200 p-4"
        >
          <YmiIsLoading />
        </div>
      </YmiField>
    </div>
  </div>
</template>

<script setup>
import dayjs from 'dayjs';
import debounce from '~/helpers/debounce';
import { useDocumentStore } from '~/stores/document';
import { useModalStore } from '~/stores/modal';

const { $api } = useNuxtApp();
const documentStore = useDocumentStore();
const modalStore = useModalStore();

const isLoading = ref(false);
const showAllRequests = ref(false);
const searchResults = ref([]);
const operationStep = ref(1);
const foundRequests = ref([]);
const names = ref('');
const lastSelectedAppealGuid = ref('');
const lastSelectedAppealName = ref('');
const wrapper = ref(null);

const handleInput = debounce(async (value) => {
  try {
    operationStep.value = 1;
    lastSelectedAppealGuid.value = '';
    lastSelectedAppealName.value = '';
    if (!value || value.length < 3) return;
    isLoading.value = true;
    const { data } = await $api.web.GetReferenceData({ search_line: value });
    searchResults.value = data.data;
  } catch (err) {
    // eslint-disable-next-line
    console.log(err);
  } finally {
    isLoading.value = false;
  }
}, 250);

const selectAppeal = async (counterparty_guid, fio) => {
  try {
    if (!counterparty_guid) return;

    names.value = fio;
    lastSelectedAppealGuid.value = counterparty_guid;
    lastSelectedAppealName.value = fio;

    isLoading.value = true;
    const { data } = await $api.web.GetPreviousAppealsRequests({
      counterparty_guid,
      return_only_last_requests: showAllRequests ? 0 : 1,
      return_only_requests: 1,
    });
    operationStep.value = 2;
    foundRequests.value = data.data;
  } catch (err) {
    // eslint-disable-next-line
    console.log(err);
  } finally {
    isLoading.value = false;
  }
};

const getInfo = async () => {
  await documentStore.GetInfo4Appeal();
  await documentStore.getPreviousAppealsByPhone();
};

const selectRequest = (document_guid) => {
  try {
    if (!document_guid) return;
    modalStore.confirm.create({
      trueCallback: async () => {
        const { data } = await $api.web.GetDocumentData({
          document_name: 'Заявка',
          document_guid,
        });

        await documentStore.specifyAppeal(data.data);

        if (documentStore.cityKLADR_residence_merged) {
          await documentStore.getDadataContainerByValue({
            query: documentStore.cityKLADR_residence_merged,
            prefix: 'cityKLADR_residence',
          });
        }

        if (documentStore.cityKLADR_registration_merged) {
          await documentStore.getDadataContainerByValue({
            query: documentStore.cityKLADR_registration_merged,
            prefix: 'cityKLADR_registration',
          });
        }

        getInfo();

        modalStore.specifyAppeal.visible = false;
      },
    });
  } catch (err) {
    // eslint-disable-next-line
    console.log(err);
  } finally {
    isLoading.value = false;
  }
};

onMounted(() => {
  if (!wrapper.value) return;
  wrapper.value.getElementsByTagName('input')[0].focus();
});
</script>
