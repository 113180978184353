<template>
  <YmiModal
    title="Информация по заявке"
    :visible="modalStore.requestInfo.visible"
    custom-class="md:w-1/2 overflow-hidden"
    @close="modalStore.requestInfo.reset"
  >
    <RequestInfo />
  </YmiModal>
</template>

<script setup>
import { useModalStore } from '~/stores/modal';

const modalStore = useModalStore();
</script>
