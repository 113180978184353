<template>
  <Dropdown
    place="top-left"
    :visible="isDropdownVisible"
    @close="hideDropdown"
  >
    <template #label>
      <YmiButton
        class="btn-error"
        size="sm"
        tabindex="0"
        :disabled="!isValidPhone || documentIsReadOnly"
        expanded
        @click="toggleDropdownVisibility"
      >
        <Icon name="close" />
        Отказать
        <Icon
          size="xs"
          :class="{
            'fill-black': isValidPhone,
            'fill-zinc-400': !isValidPhone,
          }"
          :name="isDropdownVisible ? 'caret-up' : 'caret-down'"
        />
      </YmiButton>
    </template>
    <div
      v-if="isDropdownVisible"
      class="flex h-[300px] w-[320px] flex-col gap-2 overflow-auto p-2 text-left text-xs"
    >
      <div ref="inputWrapper">
        <YmiInput
          v-model="search"
          class="grow"
          placeholder="Поиск"
        />
      </div>
      <div
        v-for="item in filtered"
        :key="item.group"
        class="flex flex-col gap-1"
      >
        <div>
          <span class="font-semibold">{{ item.group }}</span>
        </div>
        <div @mousedown="chooseHandler">
          <div
            v-for="row in item.items"
            :key="row.guid"
            class="cursor-pointer p-2 hover:bg-slate-100"
            :data-item="JSON.stringify(row)"
          >
            {{ row.name }}
          </div>
        </div>
      </div>
    </div>
  </Dropdown>
</template>

<script setup>
import { storeToRefs } from 'pinia';
import { useAppStore } from '~/stores/app';
import { useDocumentStore } from '~/stores/document';
import { useModalStore } from '~/stores/modal';
import { useReferencesStore } from '~/stores/references';

const appStore = useAppStore();
const documentStore = useDocumentStore();
const modalStore = useModalStore();
const referencesStore = useReferencesStore();

const router = useRouter();

const isDropdownVisible = ref(false);
const search = ref('');
const inputWrapper = ref(null);
const { isEmptyComplaint, isValidPhone, documentIsReadOnly } = storeToRefs(documentStore);

const comp = markRaw(resolveComponent('SelectComplaint'));

const chooseHandler = (event) => {
  try {
    const { guid, name } = JSON.parse(event.target.dataset.item);

    modalStore.$patch({ complaint: { guid, name } });

    if (event.which !== 1) return;
    modalStore.confirm.create({
      customClass: 'w-[500px]',
      dynamicComponent: referencesStore.complaints.find((i) => i.guid === guid) ? comp : '',
      title: `Отказать по причине «${name}»?`,
      trueCallback: async () => {
        await documentStore.setAppealResult(guid);
        appStore.$patch({ appealStatus: 'reject' });
        router.push('/finish');
      },
      closeCallback: () => {
        modalStore.complaint.reset();
      },
      trueDisabled: isEmptyComplaint,
    });
  } catch (err) {
    // eslint-disable-next-line
    console.log(err);
  }
};

const toggleDropdownVisibility = async () => {
  isDropdownVisible.value = !isDropdownVisible.value;
  if (isDropdownVisible.value) {
    await nextTick();
    if (!inputWrapper.value) return;
    inputWrapper.value.getElementsByTagName('input')[0].focus();
  }
};

const hideDropdown = () => {
  isDropdownVisible.value = false;
};

const options = [
  {
    group: 'Отказ',
    items: referencesStore.selectableResultsOfAppeal,
  },
  {
    group: 'Нецелевое',
    items: referencesStore.selectableCategoriesOfNonTargetedAppeals,
  },
  {
    group: 'Другое',
    items: referencesStore.selectableOtherResultsOfAppeal,
  },
];

const filtered = computed(() => {
  return options
    .map((group) => {
      return {
        group: group.group,
        items: group.items.filter((item) => {
          return item.name.toLowerCase().includes(search.value.toLowerCase());
        }),
      };
    })
    .filter((group) => group.items.length > 0);
});
</script>
