<template>
  <YmiModal
    title="Отчет по сотрудникам"
    :visible="modalStore.report.visible"
    :full-screen="!!modalStore.report.remotePdfDocument"
    custom-class="md:w-1/2 overflow-hidden"
    @close="modalStore.report.reset"
  >
    <ReportAppeals />
  </YmiModal>
</template>

<script setup>
import { useModalStore } from '~/stores/modal';

const modalStore = useModalStore();
</script>
