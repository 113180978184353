<template>
  <YmiModal
    :title="modalStore.phoneManagement.title"
    :visible="modalStore.phoneManagement.visible"
    @close="modalStore.phoneManagement.reset"
  >
    <PhoneManagementActions v-if="modalStore.phoneManagement.visible" />
  </YmiModal>
</template>

<script setup>
import { useModalStore } from '~/stores/modal';

const modalStore = useModalStore();
</script>
