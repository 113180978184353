<template>
  <select
    class="select-bordered select w-full outline-none"
    @change="handleChange"
  >
    <option
      v-for="option in props.options"
      :key="option[props.valueKey]"
      :value="option[props.valueKey]"
    >
      {{ option[props.nameKey] }}
    </option>
  </select>
</template>

<script setup>
const emit = defineEmits(['update:modelValue', 'chosen']);

const props = defineProps({
  modelValue: {
    type: [Array, Object, Number, String],
    default: '',
    required: true,
  },
  placeholder: {
    type: String,
    default: 'Выберите из списка',
  },
  options: {
    type: Array,
    default: () => [],
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  valueKey: {
    type: String,
    default: 'value',
  },
  nameKey: {
    type: String,
    default: 'name',
  },
});

const handleChange = (event) => {
  emit('chosen', event.target.value);
  emit('update:modelValue', event.target.value);
};
</script>
