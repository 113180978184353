<template>
  <div
    v-if="!modalStore.report.remotePdfDocument"
    class="flex flex-col gap-4"
  >
    <NativeSelect
      v-model="period"
      class="w-full"
      :options="periodValues"
    />
    <YmiButton
      :disabled="isLoading"
      :loading="isLoading"
      @click="getReportData"
    >
      Получить отчет
    </YmiButton>
  </div>
  <div v-else>
    <pdf :src="pdfDocument" />
  </div>
</template>

<script setup>
import { useModalStore } from '~/stores/modal';

const modalStore = useModalStore();

const isLoading = ref(false);
const period = ref(0);
const periodValues = [
  { value: 0, name: 'За текущую смену' },
  { value: 1, name: 'За текущий месяц' },
  { value: 2, name: 'За предыдущий месяц' },
];

const pdfDocument = computed(() => {
  return modalStore.report.remotePdfDocument;
});

const { $api } = useNuxtApp();

const getReportData = async () => {
  try {
    isLoading.value = true;
    const { data } = await $api.web.GetReportData({ period_option: period.value });
    modalStore.report.remotePdfDocument = data.data;
  } catch (err) {
    // eslint-disable-next-line
    console.log(err);
  } finally {
    isLoading.value = false;
  }
};
</script>
