<template>
  <YmiDropdown
    place="top-left"
    menu-class="bg-slate-50"
  >
    <template #label>
      <YmiButton
        class="btn-outline btn-ghost btn-square"
        size="sm"
      >
        <Icon
          size="sm"
          name="call"
        />
      </YmiButton>
    </template>
    <div class="flex flex-col gap-2">
      <div
        v-for="item in modalStore.phoneManagement.listActions()"
        :key="item.id"
        class="cursor-pointer rounded-lg p-2 hover:bg-slate-200"
        @click="modalStore.phoneManagement.select(item.id)"
      >
        {{ item.title }}
      </div>
    </div>
  </YmiDropdown>
</template>

<script setup>
import { useModalStore } from '~/stores/modal';

const modalStore = useModalStore();
</script>
